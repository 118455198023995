import { /*useCallback, useEffect, useMemo*/ useRef } from "react";
import salon from "./Images/sceptre_transparent_background.png";
import laptopCalendar from "./Images/laptopwithcalendar.jpg";
import phoneCalendar from "./Images/phonewithcalendar.png";
import { useState } from "react";
import { FaSquarePhoneFlip } from "react-icons/fa6";
import { IoLocation } from "react-icons/io5";
// import { PopupModal } from "react-calendly";
// import hand from "./Images/hand.png";
// import hand2 from "./Images/hand2.png";
// import leg from "./Images/leg.png";
// import styling from "./Images/styling.png";
// import vipstyling from "./Images/vipstyling.png";
// import natHstyling from "./Images/natHstyling.png";
// import braids from "./Images/braids.png";
// import kiddiesH from "./Images/kiddieshair.png";
// import kiddiesS from "./Images/kiddiesstyling.png";
// import kiddiesB from "./Images/kiddiesbraids.png";
// import wigD from "./Images/wigdesign.png";
// import wigS from "./Images/wigstyling.png";
// import wigL from "./Images/wiglaundry.png";
// import wigP from "./Images/wigpurchase.png";
// import nail1 from "./Images/nail1.jpg";
// import nail2 from "./Images/nail2.jpeg";
import nail3 from "./Images/nail3.jpg";
// import manicure1 from "./Images/manicure1.jpg";
// import manicure2 from "./Images/manicure2.jpg";
// import pedicure1 from "./Images/pedicure1.jpg";
// import pedicure2 from "./Images/pedicure2.jpg";
// import pedicure3 from "./Images/pedicure3.jpg";
// import salon1 from "./Images/salon1.jpg";
// import salon2 from "./Images/salon2.jpg";
import salon3 from "./Images/salon3.jpg";
// import kids1 from "./Images/kids1.jpg";
// import kids2 from "./Images/kids2.jpg";
// import kids3 from "./Images/kids3.jpg";
import kids4 from "./Images/kids4.jpg";
// import braids1 from "./Images/braids1.jpg";
// import braids2 from "./Images/braids2.jpg";
// import braids3 from "./Images/braids3.jpg";
// import braids4 from "./Images/braids4.jpg";
// import wigs4 from "./Images/wigs4.jpg";
import wigs5 from "./Images/wig5.jpg";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function App() {
  const [category, setCategory] = useState("");
  const [categoryItem, setCategoryItem] = useState("");
  // const [extraHeight, setExtraHeight] = useState(0);
  // const [opencategory, setOpencategory] = useState(false);
  const [opencategoryItem, setOpencategoryItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [overlayIndex, setOverlayIndex] = useState(0);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const sectionRef = useRef(null);
  // const [images, setImages] = useState([nail1, nail2]);
  // const [catItemIndex, setCatItemIndex] = useState(-1);
  // const [openModal, setOpenModal] = useState(false);
  // const [orderLink, setOrderLink] = useState("");

  var currentTime = new Date();
  // var pageSettings = {
  //   backgroundColor: "#DB2777",
  //   hideEventTypeDetails: false,
  //   hideLandingPageDetails: false,
  //   primaryColor: "00a2ff",
  //   textColor: "#fff",
  // };

  // console.log("category: ", category, ", categoryItem: ", categoryItem);

  // var utm = {
  //   utmCampaign: "Sale 2024",
  //   utmContent: `${category} : ${categoryItem}`,
  //   utmMedium: "Ad",
  //   utmSource: "SCEPTRE website",
  //   utmTerm: "Fresh start",
  // };

  // var prefill = {
  //   email: "",
  //   firstName: "",
  //   lastName: "",
  //   name: "",
  //   smsReminderNumber: "",
  //   guests: [""],
  //   customAnswers: {
  //     a1: "",
  //     a2: categoryItem,
  //     a3: `${category} : ${categoryItem}`,
  //     a4: "a4",
  //     a5: "a5",
  //     a6: "a6",
  //     a7: "a7",
  //     a8: "a8",
  //     a9: "a9",
  //     a10: "a10",
  //   },
  //   date: new Date(Date.now() + 86400000),
  // };

  // const products = useMemo(
  //   () => [
  //     {
  //       category: "Nails",
  //       items: ["Nails Enhancement and Design", "Manicure", "Pedicure"],
  //     },
  //     {
  //       category: "Salon Services",
  //       items: ["Braids", "Natural Hair Styling", "Styling", "VIP Styling"],
  //     },
  //     {
  //       category: "Sceptre Kids",
  //       items: ["Kiddies Haircut", "Kiddies Styling", "Kiddies Braids"],
  //     },
  //     {
  //       category: "Wigs",
  //       items: [
  //         "Wig Design and Creation",
  //         "Wig Styling",
  //         "Wig Laundry",
  //         "Wig Purchase",
  //       ],
  //     },
  //   ],
  //   []
  // );

  // const memoizedProducts = useMemo(() => products, [products]);
  // useEffect(() => {
  //   if (categoryItem === memoizedProducts[0].items[0]) {
  //     setImages([nail1, nail2]);
  //     setCatItemIndex(0);
  //   } else if (categoryItem === memoizedProducts[0].items[1]) {
  //     setImages([manicure1, manicure2]);
  //     setCatItemIndex(1);
  //   } else if (categoryItem === memoizedProducts[0].items[2]) {
  //     setImages([pedicure1, pedicure2, pedicure3]);
  //     setCatItemIndex(2);
  //   } else if (categoryItem === memoizedProducts[1].items[0]) {
  //     setImages([braids1, braids4]);
  //     setCatItemIndex(3);
  //   } else if (categoryItem === memoizedProducts[1].items[1]) {
  //     setImages([salon1, salon2]);
  //     setCatItemIndex(4);
  //   } else if (categoryItem === memoizedProducts[1].items[2]) {
  //     setImages([kids3]);
  //     setCatItemIndex(5);
  //   } else if (categoryItem === memoizedProducts[1].items[3]) {
  //     setImages([kids3, kids2]);
  //     setCatItemIndex(6);
  //   } else if (categoryItem === memoizedProducts[2].items[0]) {
  //     setImages([kids1, braids3]);
  //     setCatItemIndex(7);
  //   } else if (categoryItem === memoizedProducts[2].items[1]) {
  //     setImages([braids2, braids3]);
  //     setCatItemIndex(8);
  //   } else if (categoryItem === memoizedProducts[2].items[2]) {
  //     setImages([braids1, kids4, braids3]);
  //     setCatItemIndex(9);
  //   } else if (categoryItem === memoizedProducts[3].items[0]) {
  //     setImages([wigs4]);
  //     setCatItemIndex(10);
  //   } else if (categoryItem === memoizedProducts[3].items[1]) {
  //     setImages([wigs4]);
  //     setCatItemIndex(11);
  //   } else if (categoryItem === memoizedProducts[3].items[2]) {
  //     setImages([wigs4]);
  //     setCatItemIndex(12);
  //   } else if (categoryItem === memoizedProducts[3].items[3]) {
  //     setImages([wigs4]);
  //     setCatItemIndex(13);
  //   }
  // }, [memoizedProducts, categoryItem]);

  // const nextImage = useCallback(() => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  // }, [setCurrentIndex, images.length]);

  // const prevImage = useCallback(() => {
  //   setCurrentIndex(
  //     (prevIndex) => (prevIndex - 1 + images.length) % images.length
  //   );
  // }, [setCurrentIndex, images.length]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     nextImage();
  //   }, 3000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [currentIndex, nextImage]);

  const handleClick = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // const descriptions = [
  //   {
  //     image: hand,
  //     description:
  //       "With our skilled nail artists at the helm, watch creativity and style. Leveraging premium materials and avant-garde techniques, we craft exquisite, tailored designs. Elevate your nail game and make a bold, beautiful statement with each gesture.",
  //   },
  //   {
  //     image: hand2,
  //     description:
  //       "Indulge in Sceptre Luxury Salon's Manicure session, a luxurious 90-minute retreat for your nails. Our expert nail technicians meticulously shape, buff, and polish your nails to perfection. Using only the finest, premium-grade products, we ensure every detail, from cuticle care to the final polish, is executed with precision. Our goal is more than just beautifying your nails; we aim to provide a rejuvenating experience for your hands.",
  //   },
  //   {
  //     image: leg,
  //     description:
  //       "Pamper your feet with premium care and turn every moment into an opulent experience. From soothing soaks to perfect polish finishes, we craft a refreshing retreat for your feet, exuding sheer elegance.",
  //   },
  //   {
  //     image: braids,
  //     description:
  //       "From classic designs to contemporary twists, elevate your style with our impeccable braiding service. We weave tradition with trend, crafting intricate braided styles that not only protect but also celebrate your hair's natural beauty. Experience the timeless allure of braids at Sceptre Luxury Salon.",
  //   },
  //   {
  //     image: natHstyling,
  //     description:
  //       " Our seasoned stylists are versed in the artistry of enhancing natural textures, turning each curl, kink, and wave into a statement. Let us celebrate and elevate your natural crown, crafting looks that resonate with your true essence.",
  //   },
  //   {
  //     image: styling,
  //     description:
  //       "Discover the essence of transformative beauty with Sceptre Luxury Salon's Styling service. Immerse yourself in a session where our skilled stylists cater to your every hair whim, ensuring a look that radiates both elegance and everyday charm.",
  //   },
  //   {
  //     image: vipstyling,
  //     description:
  //       "Experience the pinnacle of personalization with Sceptre Luxury Salon's VIP Styling. Reserved exclusively for our distinguished clientele, this service offers unparalleled attention to detail in a serene setting. Our Director stylists harness their expertise to create looks that not only elevate your style but also resonate with your persona.",
  //   },
  //   {
  //     image: kiddiesH,
  //     description:
  //       "With expertise in catering to the delicate hair of children, our barbers and stylists craft looks ranging from classic trims to playful, modern cuts. Every haircut is tailored to comfort, ensuring a fun-filled experience that leave kids looking their charming best.",
  //   },
  //   {
  //     image: kiddiesS,
  //     description:
  //       "We handle tender tresses with care, ensuring both comfort and style for every child. From playful braids to chic updos tailored for the young trendsetter, we offer a myriad of looks that resonate with the joys of childhood.",
  //   },
  //   {
  //     image: kiddiesB,
  //     description:
  //       "Let your little one shine with Sceptre's kiddies braids service. Tailored for the tender scalps of our youngest clients, our skilled stylists craft intricate and playful designs that children adore. While ensuring a gentle touch, we create looks that are both charming and comfortable, making hair-styling a delightful experience for your child.",
  //   },
  //   {
  //     image: wigD,
  //     description:
  //       "Move beyond off-the-shelf options and collaborate with our experts to envision and craft a wig tailored exclusively for you. From selecting the finest premium hair to deciding on the perfect curl, bounce, and style, every step is guided by your desires. Revel in the unparalleled satisfaction of wearing a masterpiece created just for you",
  //   },
  //   {
  //     image: wigS,
  //     description:
  //       "Send in your wigs and let our seasoned professionals work their magic, infusing life back into each strand. Whether you desire a contemporary look or a timeless classic, we masterfully craft styles that complement your wig's inherent beauty.",
  //   },
  //   {
  //     image: wigL,
  //     description:
  //       "Revitalize your treasured wigs with Sceptre's premium wig laundry service. Entrust us with your piece, and we'll delicately cleanse, condition, and restore its original luster and bounce. Using specialized techniques and products, we guarantee a gentle yet thorough refresh, ensuring your wig retains its signature style and quality.",
  //   },
  //   {
  //     image: wigP,
  //     description:
  //       "Our wigs boast signature curls and an unmatched bounce, embodying luxury with every strand. Using only the finest premium hairs, we ensure longevity, natural sheen, and a flawless finish.",
  //   },
  // ];

  // console.log("isOpen: ", isOpen);

  // useEffect(() => {
  //   if (category === memoizedProducts[0].category) {
  //     setOrderLink("https://calendly.com/sceptreinvouge/nails-session");
  //   } else if (category === memoizedProducts[1].category) {
  //     setOrderLink("https://calendly.com/sceptreinvouge/dailyorders");
  //   } else if (category === memoizedProducts[2].category) {
  //     setOrderLink(
  //       "https://calendly.com/sceptreinvouge/scepter-children-beauty-session"
  //     );
  //   } else if (category === memoizedProducts[3].category) {
  //     setOrderLink("https://calendly.com/sceptreinvouge/wigs");
  //   }
  // }, [category, memoizedProducts]);

  const scriptContent = `
    <script src='https://square.site/appointments/buyer/widget/9ryixii8rkf1tw/L7VY3YNWNDK9F.js'></script>
  `;

  const iframeContent = `
    <html>
      <head>
        <title>SceptreInvogue</title>
      </head>
      <body>
        ${scriptContent}
      </body>
    </html>
  `;

  let isMaintenance = true;

  return (
    <>
      {isMaintenance === true ? (
        <div>
          {/* <PopupModal
        url={orderLink}
        pageSettings={pageSettings}
        utm={utm}
        prefill={prefill}
        onModalClose={() => setOpenModal(false)}
        open={openModal}
        rootElement={document.getElementById("root")}
      /> */}
          {/* <div
        className="background w-[200%] md:w-[200%]"
        // style={backgroundStyle}
      ></div> */}
          <div className="flex flex-col items-center justify-center h-screen background">
            <img
              src={salon}
              alt="left-salon"
              className=" fixed top-3 left-3 w-[80px] h-[45px] md:w-[100px] md:h-[60px]"
            />
            <div className=" z-[1]">
              <div className=" w-fit flex flex-col">
                <div className=" flex flex-col">
                  <div className="">
                    <img
                      src={salon}
                      alt="salon"
                      className="w-[250px] md:w-[300px] mx-auto"
                    />
                  </div>
                </div>
                <button
                  className="mt-4 py-3 md:py-4 px-5 md:px-16 w-fit mx-auto bg-slate-800 border-0 text-sm rounded-xl text-white font-Manrope font-bold"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    handleClick();
                  }}
                >
                  Book Appointment
                </button>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col sm:flex-row items-center justify-center ${
              opencategoryItem ? "mb-[350px]" : "mb-0"
            }`}
          >
            <div className="sm:w-[100vw] md:w-[50vw] sm:h-fit md:h-[100vh] flex items-center justify-center">
              <div className=" flex flex-col w-[75%] my-10 md:my-0">
                <div className="my-8 text-3xl md:text-4xl lg:text-5xl font-Italiana font-semibold">
                  Book a{" "}
                  <span className=" font-bold text-slate-600 font-Italiana">
                    SCEPTRE
                  </span>
                  <span className="text-slate-800 ml-2 font-Italiana font-semibold">
                    Experience
                  </span>
                </div>
                <div className="font-Manrope text-justify">
                  Book your next salon appointment with ease. From browsing
                  available times to selecting your preferred stylist, immerse
                  yourself in a world of elegance and personalized care, where
                  each session transforms your beauty aspirations into the
                  radiant, confident you.
                </div>
                <button
                  className="sm:mx-auto md:ml-0 mt-8 py-4 px-10 w-fit mx-auto bg-slate-800 border-0 text-lg rounded-xl text-white font-bold font-Manrope"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    handleClick();
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
            <div className="w-[100vw] md:w-[50vw] flex items-center justify-center">
              <div className="w-[90%] md:w-[70%] relative mx-auto md:mx-0 my-5 md:my-0">
                <img src={laptopCalendar} alt="laptopcalendar" />
                <img
                  src={phoneCalendar}
                  alt="phonecalendar"
                  className=" absolute bottom-[-45px] right-[-3px] w-[60px]  md:w-[75px] lg:w-[100px]"
                />
              </div>
            </div>
          </div>
          {/* our services */}
          <div className="mt-16 md:mt-5 mb-32">
            <div className="w-fit mx-auto text-center mb-10">
              <h2 className="text-3xl font-bold font-Italiana mb-6">
                Our Services
              </h2>
              <p className="font-Manrope">
                Transform your beauty with our top-notch services
              </p>
            </div>

            <div className="w-[95vw] mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
              <div className="border-[1px] h-[350px] rounded-lg shadow-2xl hover:bg-slate-900 hover:cursor-pointer overflow-hidden service">
                <img src={nail3} alt="nail3" className="w-[100px]" />
                <div className=" text-center font-bold font-Italiana service-title">
                  Beautify your nails
                </div>
                <div className="px-5 mt-2 text-justify describe">
                  With our skilled nail artists at the helm, watch creativity
                  and style. Leveraging premium materials and avant-garde
                  techniques, we craft exquisite, tailored designs. Elevate your
                  nail game and make a bold, beautiful statement with each
                  gesture.
                </div>
              </div>
              <div className="border-[1px] h-[350px] rounded-lg shadow-xl hover:bg-slate-900 hover:cursor-pointer overflow-hidden service">
                <img
                  src={salon3}
                  alt="salon3"
                  className="w-[80px] lg:w-[100px]"
                />
                <div className=" text-center font-bold font-Italiana service-title">
                  Saloon services
                </div>
                <div className="px-5 mt-2 text-justify describe">
                  From classic designs to contemporary twists, elevate your
                  style with our impeccable braiding service. We weave tradition
                  with trend, crafting intricate braided styles that not only
                  protect but also celebrate your hair's natural beauty.
                  Experience the timeless allure of braids at Sceptre Luxury
                  Salon.
                </div>
              </div>
              <div className="border-[1px] h-[350px] rounded-lg shadow-xl hover:bg-slate-900 hover:cursor-pointer overflow-hidden service">
                <img
                  src={kids4}
                  alt="braids"
                  className="w-[65px] lg:w-[80px]"
                />
                <div className=" text-center font-bold font-Italiana service-title">
                  Beautify your Kids
                </div>
                <div className="px-5 mt-2 text-justify describe">
                  With expertise in catering to the delicate hair of children,
                  our barbers and stylists craft looks ranging from classic
                  trims to playful, modern cuts. Every haircut is tailored to
                  comfort, ensuring a fun-filled experience that leave kids
                  looking their charming best.
                </div>
              </div>
              <div className="border-[1px] h-[350px] rounded-lg shadow-xl hover:bg-slate-900 hover:cursor-pointer service">
                <img
                  src={wigs5}
                  alt="braids"
                  className="w-[60px] lg:w-[70px]"
                />
                <div className=" text-center font-bold font-Italiana service-title">
                  Wig services
                </div>
                <div className="px-5 mt-2 text-justify describe">
                  Move beyond off-the-shelf options and collaborate with our
                  experts to envision and craft a wig tailored exclusively for
                  you. From selecting the finest premium hair to deciding on the
                  perfect curl, bounce, and style, every step is guided by your
                  desires. Revel in the unparalleled satisfaction of wearing a
                  masterpiece created just for you
                </div>
              </div>
            </div>
          </div>

          {/* large screen services*/}
          <div
            ref={sectionRef}
            className={`hidden md:flex flex-col justify-center h-[40vh] ${
              // extraHeight > 0
              //   ? `md:h-[160vh] lg:h-[160vh]`
              `md:h-[90vh] lg:h-[110vh]`
            }`}
          >
            <div className="service-background h-full flex items-center">
              <div
                className={`ml-auto mr-[15%] lg:mr-[25%] flex items-center ${
                  "h-fit"
                  // categoryItem !== "" ? "h-fit" : "h-fit"
                } max-h-fit w-[80vw] lg:w-[60vw] rounded-2xl bg-slate-900`}
              >
                <iframe
                  className="iframe-container"
                  title="SceptreInvogue"
                  srcDoc={iframeContent}
                  style={{
                    width: "100%",
                    height: "520px",
                    border: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {/* small screen services */}
          {isOpen && (
            <div className="fixed top-[10vh] overflow-y-auto bg-slate-900 z-[5] md:hidden w-screen h-[90vh]">
              {/* x close btn */}
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                  setOpencategoryItem(false);
                }}
                className="border-2 border-white z-[6] bg-black w-[30px] h-[30px] text-white rounded-[50%] font-bold text-center fixed right-[-5px] top-[8vh]"
              >
                x
              </div>
              {/*  */}
              <div className="relative py-12 h-fit">
                {/* back btn, category name div */}
                <div className="absolute top-0 left-0 p-3 flex">
                  <button
                    className="text-white"
                    onClick={() => {
                      if (categoryItem) {
                        setCategoryItem("");
                      } else {
                        if (category) {
                          setCategory("");
                        } else {
                          setIsOpen(!isOpen);
                          setOpencategoryItem(false);
                        }
                      }
                    }}
                  >
                    {category ? "Back" : "Close"}
                  </button>
                  <div className="px-3 text-white">{category}</div>
                </div>
                {/*  */}
                <iframe
                  title="SceptreInvogue"
                  srcDoc={iframeContent}
                  style={{ width: "100%", height: "500px", border: "none" }}
                />
              </div>
            </div>
          )}
          {/* About us */}
          <div
            className={`text-center px-6 md:px-36 ${
              opencategoryItem ? "mt-[300px] md:mt-[500px]" : "mt-28 md:mt-20"
            }`}
          >
            <hr className="w-[50px] rotate-[90deg] border-black mx-auto my-5" />
            <br />
            <div className="mt-4 font-Manrope">
              <h1 className=" font-bold text-3xl md:text-5xl my-5 font-Italiana">
                About SCEPTRE INVOGUE
              </h1>
              Sceptre Invogue Luxury Salon is a world of unparalleled luxury and
              indulgence in the ultimate beauty experience. Our exquisite salon
              is an oasis of refinement, offering bespoke services that cater to
              the discerning tastes of the most elite clientele. At Sceptre
              Invogue, we believe that true beauty lies in individuality. That
              is why we offer a range of personalized services tailored to your
              unique needs, ensuring that every visit to our salon is an
              unforgettable journey of self-discovery.
            </div>
            <div className="my-10 font-Manrope">
              <h1 className=" font-semibold text-xl my-3 font-Italiana">
                Services
              </h1>
              Whether you are seeking a classic, refined look or something more
              daring and avant-garde, our team of professionals are dedicated to
              bringing out the best in you, using the finest products and
              techniques to create a look that is distinctly yours. Our services
              include everything from relaxing manicures and pedicures to the
              most exquisite hair styling, barber services and human hair
              extensions. Come and indulge in a world of luxury beauty - where
              elegance meets sophistication. Sceptre Invogue Luxury Beauty Salon
              - worth experiencing!
            </div>
            <hr className="w-[100px] border-black mx-auto" />
          </div>
          <div className=" bg-black h-fit md:h-[55vh] mt-24 flex flex-col font-Manrope">
            <div className="flex flex-col md:flex-row text-white w-[90vw] md:w-[80vw] lg:w-[70vw] mx-auto flex-1">
              <div className="w-[100%] md:w-[60%] lg:w-[35%] mx-auto">
                <div className="mt-10 text-3xl text-center md:text-left font-Italiana">
                  Contact information
                </div>
                <div className="mt-10 mb-1 pr-6 text-center md:text-left flex md:items-center justify-center md:justify-normal">
                  <FaSquarePhoneFlip className=" text-slate-200 text-lg" />
                  <div className="ml-4">+61 468 951 394</div>
                </div>
                <div className="my-2 pr-6 text-center md:text-left flex md:items-center justify-center md:justify-normal">
                  <MdEmail className="text-slate-200 text-2xl" />
                  <div className="ml-4 w-fit">
                    contactcenter@sceptreinvouge.com
                  </div>
                </div>
                <div className="mt-0 pr-6 text-center md:text-left flex md:items-center justify-center md:justify-normal">
                  <IoLocation className="text-slate-200 text-3xl" />
                  <div className="ml-2 w-fit">
                    1/247 Sandy Bay Road, Sandy Bay, TAS, Australia, Tasmania
                  </div>
                </div>
              </div>
              <div className="w-[100%] md:w-[25%] mx-auto flex flex-col items-center">
                <div className="mt-10 text-3xl  text-center md:text-left font-Italiana">
                  Follow us
                </div>
                <div className="flex w-fit items-center">
                  <a
                    href="https://www.facebook.com/profile.php?id=61557586151168"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook className="text-3xl" />
                  </a>
                  <a
                    href="https://www.instagram.com/scepter_envogue?utm_source=qr&igsh=bHVhNnh1aTVidW1s"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-3"
                  >
                    <AiFillInstagram className="text-4xl" />
                  </a>
                </div>
              </div>
            </div>
            <hr className="w-[90vw] mx-auto" />
            <div className="text-center text-white my-5 flex items-center justify-center font-Manrope text-xs">
              &copy; {currentTime.getFullYear()} by
              <div className=" flex flex-col justify-center text-xl text-slate-400 animate-pulse mx-2">
                Sceptre Invogue
              </div>
              | All Rights Reserved
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center pt-[25%] font-bold text-2xl">
          The site is under maintenance
        </div>
      )}
    </>
  );
}

export default App;
